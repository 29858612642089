<template>
    <main class="round-post-page">

        <section>
            <div class="container py-5">
                <div class="row">

                    <div class="md-down:col-12 lg:col-5 xl:col-3">
                        <div class="sticky-top" style="top: 85px;">
                            <mega-card class="card-round">
                                <mega-youtube ratio="4x3" :src="round.youtube_id"/>
                            </mega-card>
                        </div>
                    </div>

                    <div class="md-down:col-12 lg:col-7 xl:col-9">

                        <mega-card :title="$t('round_yt_title')">
                            <div class="card-body">
                                <mega-input :label="$t('round_yt_url')" v-model="round.youtube_id" @input="formatYoutube"/>
                            </div>
                        </mega-card>

                        <mega-card :title="$t('round_options')">
                            <div class="card-body">

                                <div class="row">
									<div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
										<mega-range class="bg-primary mb-0" :label="$t('height')" v-model="round.height" :min="10" :max="3000" :step="10"/>
										<div class="d-flex justify-content-between text-muted">
											<small>10</small>
											<small>3000</small>
										</div>
										<mega-number :min="10" :max="3000" :step="10" v-model="round.height"/>
									</div>

									<div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">

										<mega-range class="bg-primary mb-0" :label="$t('round_plane_height')" v-model.number="round.ref_plane_height" :min="-1000" :max="1000" :step="0.01"/>
										<div class="d-flex justify-content-between text-muted">
											<small>-1000m</small>
											<small>1000m</small>
										</div>

										<mega-number :min="-1000" :max="1000" :step="0.01" v-model.number="round.ref_plane_height"/>
									</div>
								</div>

                                <mega-textarea :label="$t('desc')" v-model="round.message" rows="3"/>
                            </div>
                        </mega-card>

                        <map-card ratio="16x9"
                                  @update="updatePosition"
                                  :lat="round.lat"
                                  :lng="round.lng">

                            <mega-switch class="mb-0 w-100" :label="$t('hide_loc')" v-model="round.is_location_hidden"/>
                            <small class="text-muted">{{ $t('hide_loc_desc') }}</small>
                        </map-card>
                    </div>
                </div>

                <div class="navbar">
                    <div class="navbar-group ml-auto">
                        <mega-button class="btn-success" :loading="loading" :disabled="!valid" @click="submit">{{ $t('round_post') }}</mega-button>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    import MapCard from '../Round_Post/components/map'
    import GroupSelect from '../../components/group-select'

    export default {
        name: 'RoundPostYoutubePage',
        props: ['arr_id'],
        data() {
            return {
                users: [],
                models: [],
                loading: false,
                rendering: false,
                playVideo: false,
                filter: {
                    type: undefined,
                    group: undefined,
                },
                sliderOption: {
                    spaceBetween: 30,
                    slidesPerView: 'auto'
                },
                round: {
                    lat: 59.9598743,
                    lng: 30.2965115,
					height: 100,
					ref_plane_height: 0,
                    message: '',
                    youtube_id: '',
                    idt_arp: undefined,
                    idc_round_type: 9,
                    visible_in_explore: false,
                    idt_model_modif: undefined,
                    preview: undefined,
                    is_location_hidden: false,
                    place_name: '',
                    place_address: '',
                    profile: {}
                },
            }
        },
        computed: {
            valid() {
                let required = [
                    'idc_round_type',
                    'idt_arp',
					'youtube_id',
					'preview',
                    'lat',
                    'lng',
                    'height'
                ];
                let valid = true;

                Object.keys(this.round).map(prop =>
                {
                    if(required.includes(prop) && this.round[prop] === undefined)
                    {
                        valid = false;
                    }
                });

                return valid;
			},
			
			validURL() {
				const regexp = new RegExp("(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&\\/=]*)","g");
				const res = this.round.youtube_id.match(regexp);
				if (res === null) return false;
				return true;
			}
        },
        mounted() {
			this.$navbar.name = this.$t('make_round_yt');
			
			this.round.idt_arp = Number(this.arr_id);

            //this.loadModels();
        },
        methods: {

            search(search) {

                let params = {};

                if(search) params['search'] = search;

                this.$api.v1.get('/userList', {params})
                    .then((response) => {

                        this.users = [];

                        response.data.users.map(user => {
                            this.users.push({
                                value: user.idt_user,
                                name: user.name+(user.username ? ' ('+user.username+')' : '')
                            })
                        })
                    })
            },

            submit() {
                this.loading = true;

                let data = this.round;
                let formData = new FormData();

                delete data.profile;
                data['height'] = data['height'] * 0.01;

                Object.keys(data).map(prop => {
                    if(this.round[prop] !== undefined)
                        formData.append(prop, this.round[prop]);
				});

                this.$api.v2.post('/user/round', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((response) => {
                        this.loading = false;
                        this.$router.push('/round/'+response.data['round'].idt_round)
                    })
            },

            loadModels() {
                let params = {};

                if(this.filter.group !== undefined) params.idt_group = this.filter.group;
                // if(this.round.idc_round_type) params.idc_round_type = this.round.idc_round_type;

                this.$api.v2.get('/models', {params})
                    .then(response => {

                        this.models = [];

                        if(response.data.models)
                            response.data.models.map(item => {
                                if(item.modifications)
                                    this.models.push(item)
                            });
                    })
            },

            video_toggle() {
                if(this.playVideo)
                    this.$refs['video'].pause();
                else
                    this.$refs['video'].play();

                this.playVideo = !this.playVideo;
            },

            updatePosition(location) {
                this.round.place_address = location.address;
                this.round.place_name = location.name;
                this.round.lat = location.lat;
                this.round.lng = location.lng;
			},
			
			formatYoutube() {
				if (!this.validURL) {
					return;
				}
				
				try {
					const url = new URL(this.round.youtube_id);
					const id = url.searchParams.get("v");

					if (id && id.length === 11) {
						this.round.youtube_id = id;
						this.renderPreview(id);
					}
				} catch (error) {
					console.log(error);
				}
			},

            renderPreview(id) {
				
				const url = `http://i3.ytimg.com/vi/${ id }/maxresdefault.jpg`;
				
				this.getDataUri(url, dataUri => {
					const file = this.dataURLtoFile(dataUri, 'preview.jpeg');
					this.round.preview = file;
				});
            },
			
			getDataUri(url, callback) {
				var image = new Image();
				image.crossOrigin = 'anonymous';

				image.onload = () => {
					var canvas = document.createElement('canvas');
					canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
					canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size

					canvas.getContext('2d').drawImage(image, 0, 0);

					callback(canvas.toDataURL('image/jpeg'));
				};

				image.src = url;
			},

			dataURLtoFile(dataurl, filename) {
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);

				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while(n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				return new File([u8arr], filename, { type: mime });
			}
        },
        components: {
            MapCard,
            GroupSelect
        }
    }
</script>
